<nb-card>
  <nb-card-header>
    <h3 class="text-primary px-lg-3">Terms and Conditions</h3>
    <section class="another-action" aria-label="Register">
      Return page <a class="text-link" routerLink="/auth/register">Register</a>
    </section>
  </nb-card-header>
  <nb-card-body class="px-md-3 px-lg-5">
    <p class="p-2 mb-4">Last Modified: January 31, 2024</p>
    <p class="p-2 mb-4">
      Please read the Terms and Conditions carefully before you start to use the
      Site.
      <strong
        >The Terms and Conditions govern your access to and use of the Site and
        the services provided by Artistic Visions LLC </strong
      >("<strong>Company</strong>," "<strong>we</strong>," or
      "<strong>us</strong>")<strong
        >, whether you are a client of the Company&rsquo;s through a license
        agreement with the Company (a &ldquo;Licensee&rdquo;) or a client, or
        potential client or site visitor, of a Licensee who gains access to the
        benefits of the Site by contracting with a Licensee.
      </strong>
    </p>
    <p class="p-2"><strong>Acceptance of the Terms and Conditions </strong></p>
    <p class="p-2 mb-4">
      These Terms and Conditions are entered into by and between You and the
      Company. The following terms and conditions, together with the service
      contract, estimate, or order agreed to by you and the Company and any
      documents they expressly incorporate by reference (collectively, "<strong
        >Terms and Conditions</strong
      >"), govern your access to and use of the Company&rsquo;s website at
      www.artisticvisions.com and www.avwaveinteractivesolutions.com
      (collectively, the &ldquo;<strong>Website</strong>&rdquo;) or the web
      application at www.avwaveinteractive.com or www.avwaveinteractiveapp.com
      (the &ldquo;<strong>App</strong>&rdquo;), including any content,
      functionality, and services offered on or through the Website or App
      (collectively, the &ldquo;<strong>Site</strong>&rdquo;), whether as a
      guest or a registered user.
    </p>
    <p class="p-2">
      <strong
        >By using the Site or by clicking to accept or agree to the Terms and
        Conditions when this option is made available to you, you accept and
        agree to be bound and abide by these Terms and Conditions and our
        Privacy Policy, found at
        <a
          href="https://artisticvisions.com/privacy-policy/"
          target="_blank"
          rel="noopener"
          style="color: #007bff"
          >Privacy Policy</a
        >, incorporated herein by reference (the &ldquo;Privacy Policy&rdquo;). </strong
      >If you do not want to agree to these Terms and Conditions or the Privacy
      Policy, you must not access or use the Site.
    </p>
    <p class="p-2 mb-4">
      This Site is offered and available to users who are 16 years of age or
      older and reside in the United States, or any of its territories or
      possessions. By using this Site, you represent and warrant that you are of
      legal age to form a binding contract with the Company by agreeing to these
      Terms and Conditions and meet all of the foregoing eligibility
      requirements. If you do not meet all of these requirements, you must not
      access or use the Site.
    </p>
    <p class="p-2"><strong>Changes to the Terms and Conditions </strong></p>
    <p class="p-2 mb-4">
      We may revise and update these Terms and Conditions from time to time in
      our sole discretion. All changes are effective immediately when we post
      them, and apply to all access to and use of the Site thereafter. However,
      any changes to the dispute resolution provisions set out in Governing Law
      and Jurisdiction will not apply to any disputes for which the parties have
      actual notice on or before the date the change is posted on the Site.
    </p>
    <p class="p-2">
      Your continued use of the Site following the posting of revised Terms and
      Conditions means that you accept and agree to the changes. You are
      expected to check this page frequently so you are aware of any changes, as
      they are binding on you.
    </p>
    <p class="p-2 mb-4">
      <strong
        >Use of the Site; Accessing the Site; and Account Security
      </strong>
    </p>
    <p class="p-2">
      Your access to the Site and the Company&rsquo;s services is dependent upon
      your reasonable use of the Site and services in compliance with these
      Terms and Conditions. We reserve the right to withdraw or amend this Site,
      and any service or material we provide on the Site, in our sole discretion
      without notice. We will not be liable if for any reason all or any part of
      the Site is unavailable at any time or for any period. From time to time,
      we may restrict access to some parts of the Site, or the entire Site, to
      users, including registered users.
    </p>
    <p class="p-2">You are responsible for both:</p>
    <ul class="list-unstyled">
      <li class="p-2">
        Making all arrangements necessary for you to have access to the Site.
      </li>
      <li class="p-2">
        Ensuring that all persons who access the Site through your internet
        connection are aware of these Terms and Conditions and comply with them.
      </li>
    </ul>
    <p class="p-2 mb-4">
      To access the Site or some of the resources it offers, you may be asked to
      provide certain registration details or other information. It is a
      condition of your use of the Site that all the information you provide on
      the Site is correct, current, and complete. You agree that all information
      you provide to register with this Site or otherwise, including, but not
      limited to, through the use of any interactive features on the Site, is
      governed by our&nbsp;<em>Privacy Policy </em>(linked above), and you
      consent to all actions we take with respect to your information consistent
      with our Privacy Policy.
    </p>
    <p class="p-2">
      If you choose, or are provided with, a user name, password, or any other
      piece of information as part of our security procedures, you must treat
      such information as confidential, and you must not disclose it to any
      other person or entity. You also acknowledge that your account is personal
      to you and agree not to provide any other person with access to this Site
      or portions of it using your user name, password, or other security
      information. You agree to notify us immediately of any unauthorized access
      to or use of your user name or password or any other breach of security.
      You also agree to ensure that you exit from your account at the end of
      each session. You should use particular caution when accessing your
      account from a public or shared computer so that others are not able to
      view or record your password or other personal information.
    </p>
    <p class="p-2 mb-4">
      We have the right to disable any user name, password, or other identifier,
      whether chosen by you or provided by us, at any time in our sole
      discretion for any or no reason, including if, in our opinion, you have
      violated any provision of these Terms and Conditions.
    </p>
    <p class="p-2"><strong>Intellectual Property Rights </strong></p>
    <p class="p-2 mb-4">
      The Site and its entire contents, features, and functionality (including
      but not limited to all information, software, text, displays, images,
      video, and audio, and the design, selection, and arrangement thereof) are
      owned by the Company, its licensors, or other providers of such material
      and are protected by United States and international copyright, trademark,
      patent,
    </p>

    <p class="p4 mb-4">&nbsp;</p>
    <p class="p2 mb-4"><strong>Reliance on Information Posted </strong></p>
    <p class="p2 mb-4">
      The information presented on or through the Site is made available solely
      for general information purposes. We do not warrant the accuracy,
      completeness, or usefulness of this information. Any reliance you place on
      such information is strictly at your own risk. We disclaim all liability
      and responsibility arising from any reliance placed on such materials by
      you or any other visitor to the Site, or by anyone who may be informed of
      any of its contents.
    </p>
    <p class="p2 mb-4">
      This Site may include content provided by third parties, including
      materials provided by Licensees, other users, third-party licensors,
      syndicators, aggregators, and/or reporting services. All statements and/or
      opinions expressed in these materials, and all articles and responses to
      questions and other content, other than the content provided by the
      Company, are solely the opinions and the responsibility of the person or
      entity providing those materials. These materials do not necessarily
      reflect the opinion of the Company. We are not responsible, or liable to
      you or any third party, for the content or accuracy of any materials
      provided by any third parties.
    </p>
    <p class="p2 mb-4"><strong>Changes to the Site </strong></p>
    <p class="p2 mb-4">
      We may update the content on this Site from time to time, but its content
      is not necessarily complete or up-to-date. Any of the material on the Site
      may be out of date at any given time, and we are under no obligation to
      update such material.
    </p>
    <p class="p2 mb-4">
      <strong>Information About You and Your Visits to the Site </strong>
    </p>
    <p class="p2 mb-4">
      All information we collect on this Site is subject to our Privacy Policy
      (linked above). By using the Site, you consent to all actions taken by us
      with respect to your information in compliance with the Privacy Policy.
    </p>
    <p class="p2 mb-4"><strong>Terms of Sale </strong></p>
    <p class="p2 mb-4">
      All purchases through the Site or other transactions for the sale of
      Services through the Site, or resulting from visits made by you to the
      Site, are governed by these Terms of Sale and any additional terms which
      are set forth in our invoices and proposals:
    </p>
    <ul class="ul1">
      <li class="li2">
        Scope of Services and Deliverables
        <ul class="ul1">
          <li class="li2">
            We will provide visualization services and / or the license
            subscription for the App upon Client&rsquo;s request (the
            &ldquo;<strong>Services</strong>&rdquo;). Such Services include but
            are not limited to 2D black &amp; white or color site plans, black
            &amp; white or color elevations, drone photography/drone mapping,
            photorealistic site plan options, 2D &amp; 3D floor plan options, 3D
            exterior and interior renderings, 3D photorealistic animated tours,
            CGI aerials with drone integration, 360&deg; panorama renderings and
            watercolor renderings, and any other rendering and visualization
            services that we offer (**)
          </li>
        </ul>
      </li>
      <li class="li2">Prices and Plans</li>
    </ul>
    <table class="table">
      <tbody>
        <tr>
          <td style="width: 71.2752%" valign="top">
            <p class="mb-4">&nbsp;</p>
          </td>
          <td style="width: 17.9866%" valign="top">
            <p class="mb-4">BASIC</p>
          </td>
          <td style="width: 10.7383%">CUSTOM</td>
        </tr>
        <tr>
          <td style="width: 71.2752%" valign="top">
            <p class="mb-4">
              Price per month (Apply 10% off per semester or 20% off per year if
              paid in advance)
            </p>
          </td>
          <td style="width: 17.9866%" valign="top">
            <p class="mb-4">100 USD</p>
          </td>
          <td style="width: 10.7383%" valign="top">
            <p class="mb-4">Custom</p>
          </td>
        </tr>
        <tr>
          <td style="width: 71.2752%" valign="top">
            <p class="mb-4">Features/Modules available</p>
          </td>
          <td style="width: 17.9866%" valign="top">
            <p class="mb-4">Up to 3</p>
          </td>
          <td style="width: 10.7383%" valign="top">
            <p class="mb-4">Custom</p>
          </td>
        </tr>
        <tr>
          <td style="width: 71.2752%" valign="top">
            <p class="mb-4">Lead / Admin panel</p>
          </td>
          <td style="width: 17.9866%" valign="top">
            <p class="mb-4">Included</p>
          </td>
          <td style="width: 10.7383%" valign="top">
            <p class="mb-4">Included</p>
          </td>
        </tr>
        <tr>
          <td style="width: 71.2752%" valign="top">
            <p class="mb-4">Support</p>
          </td>
          <td style="width: 17.9866%" valign="top">
            <p class="mb-4">Monday to Friday</p>
            <p class="mb-4">(9 to 5 pm)</p>
          </td>
          <td style="width: 10.7383%" valign="top">
            <p class="mb-4">Monday to Friday</p>
            <p class="mb-4">(9 to 5 pm)</p>
          </td>
        </tr>
        <tr>
          <td style="width: 71.2752%" valign="top">
            <p class="mb-4">Capacity</p>
          </td>
          <td style="width: 17.9866%" valign="top">
            <p class="mb-4">Up to 10 elevations</p>
          </td>
          <td style="width: 10.7383%" valign="top">
            <p class="mb-4">Custom</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table">
      <tbody>
        <tr>
          <td style="width: 71.1798%" valign="top">
            <p class="mb-4">Set up cost (*)</p>
          </td>
          <td style="width: 17.9623%" valign="top">
            <p class="mb-4">Not included</p>
          </td>
          <td style="width: 10.8579%" valign="top">
            <p class="mb-4">Not included</p>
          </td>
        </tr>
        <tr>
          <td style="width: 71.1798%" valign="top">
            <p class="mb-4">Renderings/visuals (**)</p>
          </td>
          <td style="width: 17.9623%" valign="top">
            <p class="mb-4">Not included</p>
          </td>
          <td style="width: 10.8579%" valign="top">
            <p class="mb-4">Not included</p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="p1 mb-4">&nbsp;</p>
    <p class="p1 mb-4">
      One hour of client training included. Additional training time required by
      client will be charged at $85 USD per hour.
    </p>
    <p class="p1 mb-4">
      (*) Set up cost is a one-time payment to be quoted based on the chosen
      subscription (Starting at 250 USD for basic subscriptions). Set up cost
      includes uploading clients&rsquo; logo, base colors and app deploy.
    </p>
    <p class="p1 mb-4">
      (**) Renderings/visuals are one-time payment according to pricing
      structure explained further per render, per interactive element and
      options.
    </p>
    <ol class="ol1">
      <li class="li1">
        Base Renderings. To be quoted based on the specific project requirement,
        information and existing drawings/floorplans.
      </li>
      <li class="li1">
        Interactive elements and options. Any surface or texture that needs to
        be rendered will be considered an option. $50 per interactive element
        for renderings (interior/exterior). Applicable to those elements that
        have any different feature from the original one.
      </li>
    </ol>
    <ul>
      <li style="list-style-type: none">
        <ol class="ol2">
          <li class="li3">
            The above cost for rendering interactivity considers up to 5 options
            per interactive element. Additional options charged at $10 per
            option.
          </li>
          <li class="li3">$50 per option for floorplans.</li>
          <li class="li3">
            Interactive elements and options for the 360 View to be quoted based
            on each specific project requirement and option combinations.
          </li>
          <li class="li1">
            Interactive Site Plan to be quoted based on each specific project
            requirement and option combinations.
          </li>
        </ol>
      </li>
      <li style="list-style-type: none">&nbsp;</li>
      <li style="list-style-type: none">
        Payment Terms&nbsp;
        <ul>
          <li>
            <span
              style="
                font-family: Inter, -apple-system, BlinkMacSystemFont,
                  'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
                  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
                  serif;
              "
              >The prices payable by you for the services supplied by the
              Company will be specified in the applicable proposal and according
              to the plans and options described above. Unless otherwise
              expressly stated in a proposal, payments are due the last day of
              each month. If Client does not pay an invoiced amount within
              terms, Company reserves the right to suspend the Services. Should
              the client re-subscribe after suspension of services, additional
              re-setup costs may apply. In the event that Client&rsquo;s account
              is more than ninety (90) days in arrears, Client shall reimburse
              Company for the reasonable costs, including attorney&rsquo;s fees,
              of collecting such amounts from Client.</span
            >
          </li>
          <li class="li4">
            Client shall make all payments hereunder by wire, check, or credit
            card and in US dollars.
          </li>
          <li class="li2">
            Client shall pay interest on all late payments at the lesser of the
            rate of 1.5% per month or the highest rate permissible under
            applicable law, calculated daily and compounded monthly. Client
            shall reimburse the Company for all costs incurred in collecting any
            late payments, including, without limitation, attorneys' fees. In
            addition to all other remedies available under these Terms or at law
            (which the Company does not waive by the exercise of any rights
            hereunder), the Company shall be entitled to suspend the Services if
            Client fails to pay any amounts when due hereunder.
          </li>
          <li class="li2">
            Client shall not withhold payment of any amounts due and payable by
            reason of any set-off of any claim or dispute with the Company,
            whether relating to the Company&rsquo;s breach, bankruptcy or
            otherwise.
          </li>
          <li class="li2">
            Changes in, or receipt of incomplete/uncoordinated drawing sets are
            initiation of services may require additional 3D modeling services
            which will be billed at a rate of $85/hr. If this is the case, we
            will send a separate proposal for the price and delivery schedule to
            be approved by Client before making any changes.
          </li>
        </ul>
      </li>
    </ul>
    <p class="p7">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Additional Conditions</p>
    <ul>
      <li style="list-style-type: none">
        <ul>
          <li class="p7">
            Once a proposal is approved and the final project delivered, client
            agrees to pay according the agreed-upon payment terms and price,
            with such terms included in these Terms and Conditions and each
            individual proposal.
          </li>
          <li class="p7">
            Client understands that drawings and renderings are approximates
            that are for illustrative purposes only.
          </li>
          <li class="p7">
            Client understands and accepts that the services that are provided
            to Client may be used by us for promotional purposes (portfolio,
            website, social media, flyers, etc.). If such services are used by
            us for promotional purposes, proper credit will be attributed to
            Client.
          </li>
        </ul>
      </li>
    </ul>
    <p class="p7">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Termination</p>
    <ul>
      <li style="list-style-type: none">
        <ul>
          <li class="p7">
            Any of the parties may terminate a project upon written notice at
            any time. Any termination will not affect Client&rsquo;s obligation
            to pay us for services previously rendered.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      THE COMPANY MAKES NO WARRANTY WHATSOEVER WITH RESPECT TO THE SERVICES,
      INCLUDING&nbsp;ANY (a) WARRANTY OF MERCHANTABILITY; (b) WARRANTY OF
      FITNESS FOR A PARTICULAR PURPOSE; OR (c) WARRANTY AGAINST INFRINGEMENT OF
      INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY, WHETHER EXPRESS OR IMPLIED
      BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR
      OTHERWISE.
    </p>
    <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Limitation of Liability</p>
    <ul>
      <li style="list-style-type: none">
        <ul>
          <li class="p1 mb-4">
            IN NO EVENT SHALL THE COMPANY BE LIABLE TO CLIENT OR ANY THIRD PARTY
            FOR ANY LOSS OF USE, REVENUE OR PROFIT [OR LOSS OF DATA OR
            DIMINUTION IN VALUE], OR FOR ANY CONSEQUENTIAL, INDIRECT,
            INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHETHER ARISING
            OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
            OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND
            WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER
            REMEDY OF ITS ESSENTIAL PURPOSE.
          </li>
          <li class="p1 mb-4">
            IN NO EVENT SHALL THE COMPANY&rsquo;S AGGREGATE LIABILITY ARISING
            OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR
            RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
            OTHERWISE, EXCEED THE TOTAL OF THE AMOUNTS PAID TO THE COMPANY FOR
            THE SERVICES SOLD HEREUNDER.
          </li>
        </ul>
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul class="ul1">
      <li class="li1">
        Force Majeure. The Company shall not be liable or responsible to Client,
        nor be deemed to have defaulted or breached these Terms and Conditions,
        for any failure or delay in fulfilling or performing any term of these
        Terms and Conditions or any invoice or proposal when and to the extent
        such failure or delay is caused by or results from acts or circumstances
        beyond the reasonable control of the Company including, without
        limitation, acts of God, flood, fire, earthquake, explosion,
        governmental actions, war, invasion, or hostilities (whether war is
        declared or not), terrorist threats or acts, riot, or other civil
        unrest, national emergency, revolution, insurrection, epidemic,
        lockouts, strikes or other labor disputes (whether or not relating to
        either party's workforce), or restraints or delays affecting carriers or
        inability or delay in obtaining supplies of adequate or suitable
        materials, materials or telecommunication breakdown or power outage,
        provided that, if the event in question continues for a continuous
        period in excess of ten (10) days, Client shall be entitled to give
        notice in writing to Company to terminate Services.
      </li>
    </ul>
    <p class="p2 mb-4">&nbsp;</p>
    <p class="p1 mb-4">
      Additional terms and conditions may also apply to specific portions,
      services, or features of the Site. All such additional terms and
      conditions are hereby incorporated by this reference into these Terms and
      Conditions.
    </p>
    <p class="p1 mb-4">
      <strong>Linking to the Site and Social Media Features </strong>
    </p>
    <p class="p1 mb-4">
      You may link to our homepage, provided you do so in a way that is fair and
      legal and does not damage our reputation or take advantage of it, but you
      must not establish a link in such a way as to suggest any form of
      association, approval, or endorsement on our part without our express
      written consent.
    </p>
    <p class="p1 mb-4">
      This Site may provide certain social media features that enable you to:
    </p>
    <ul>
      <li class="p4">
        Link from your own or certain third-party websites to certain content on
        this Site.
      </li>
      <li class="p4">
        Send emails or other communications with certain content, or links to
        certain content, on this Site.
      </li>
      <li class="p1 mb-4">
        Cause limited portions of content on this Site to be displayed or appear
        to be displayed on your own or certain third-party websites.
      </li>
    </ul>
    <p class="p1 mb-4">
      You may use these features solely as they are provided by us, and solely
      with respect to the content they are displayed with, and otherwise in
      accordance with any additional terms and conditions we provide with
      respect to such features. Subject to the foregoing, you must not:
    </p>
    <ul>
      <li class="p4">
        Establish a link from any website that is not owned by you.
      </li>
      <li class="p4">
        Cause the Site or portions of it to be displayed on, or appear to be
        displayed by, any other site, for example, framing, deep linking, or
        in-line linking.
      </li>
      <li class="p1 mb-4">
        Link to any part of the Site other than the homepage.
      </li>
      <li class="p1 mb-4">
        Otherwise take any action with respect to the materials on this Site
        that is inconsistent with any other provision of these Terms and
        Conditions.
      </li>
    </ul>
    <p class="p1 mb-4">
      The website from which you are linking, or on which you make certain
      content accessible, must comply in all respects with the Content Standards
      set out in these Terms and Conditions.
    </p>
    <p class="p1 mb-4">
      You agree to cooperate with us in causing any unauthorized framing or
      linking immediately to stop. We reserve the right to withdraw linking
      permission without notice.
    </p>
    <p class="p1 mb-4">
      We may disable all or any social media features and any links at any time
      without notice in our discretion.
    </p>
    <p class="p1 mb-4"><strong>Links from the Site </strong></p>
    <p class="p1 mb-4">
      If the Site contains links to other sites and resources provided by third
      parties, these links are provided for your convenience only. This includes
      links contained in advertisements, including banner advertisements and
      sponsored links. We have no control over the contents of those sites or
      resources, and accept no responsibility for them or for any loss or damage
      that may arise from your use of them. If you decide to access any of the
      third-party websites linked to this Site, you do so entirely at your own
      risk and subject to the terms and conditions of use for such websites.
    </p>
    <p class="p1 mb-4"><strong>Geographic Restrictions </strong></p>
    <p class="p1 mb-4">
      The owner of the Site is based in the State of Florida in the United
      States. We provide this Site for use only by persons located in the United
      States. We make no claims that the Site or any of its content is
      accessible or appropriate outside of the United States. Access to the Site
      may not be legal by certain persons or in certain countries. If you access
      the Site from outside the United States, you do so on your own initiative
      and are responsible for compliance with local laws.
    </p>
    <p class="p1 mb-4"><strong>Disclaimer of Warranties </strong></p>
    <p class="p1 mb-4">
      You understand that we cannot and do not guarantee or warrant that files
      available for downloading from the internet or the Site will be free of
      viruses or other destructive code. You are responsible for implementing
      sufficient procedures and checkpoints to satisfy your particular
      requirements for anti-virus protection and accuracy of data input and
      output, and for maintaining a means external to our site for any
      reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE
      WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
      DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL
      MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA,
      OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
      SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF
      ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
    </p>
    <p class="p1 mb-4">
      YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
      THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY
      SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS"
      AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
      EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE
      COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
      COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF
      THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
      ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
      WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
      WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
      DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
      AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE
      WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
      OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
    </p>
    <p class="p1 mb-4">
      TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
      WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
      OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
      NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
    </p>
    <p class="p1 mb-4">
      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.
    </p>
    <p class="p1 mb-4"><strong>Limitation on Liability </strong></p>
    <p class="p1 mb-4">
      TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS
      AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
      OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
      THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
      USE, THE SITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR
      SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
      CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
      INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
      PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
      GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
      BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
    </p>
    <p class="p1 mb-4">
      The limitation of liability set out above does not apply to liability
      resulting from our gross negligence or willful misconduct.
    </p>
    <p class="p1 mb-4">
      THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.
    </p>
    <p class="p1 mb-4">
      <strong>Representations and Warranties of Licensee </strong>
    </p>
    <p class="p1 mb-4">
      Each Licensee represents and warrants that it is in compliance with all
      applicable laws and regulations for its business, its content and
      materials on this Site, and each of its users and its users' data,
      including, but not limited to, those laws relating to data protection and
      privacy and United States and international copyright, trademark, patent,
      trade secret, and other intellectual property or proprietary rights laws.
      Additionally, each Licensee represents and warrants that it is in
      compliance with every agreement governing its business which directly or
      indirectly affects the Site.
    </p>
    <p class="p5">
      <span class="s3"><strong>Indemnification&nbsp;</strong></span>
    </p>
    <p class="p1 mb-4">
      You agree to defend, indemnify, and hold harmless the Company, its
      affiliates, licensors, and service providers, and its and their respective
      officers, directors, employees, contractors, agents, licensors, suppliers,
      successors, and assigns from and against any claims, liabilities, damages,
      judgments, awards, losses, costs, expenses, or fees (including reasonable
      attorneys' fees) arising out of or relating to your violation of these
      Terms and Conditions or your use of the Site, including, but not limited
      to, your User Contributions, any use of the Site's content, services, and
      products other than as expressly authorized in these Terms and Conditions,
      or your use of any information obtained from the Site.
    </p>
    <p class="p1 mb-4"><strong>Governing Law and Jurisdiction </strong></p>
    <p class="p1 mb-4">
      All matters relating to the Site and these Terms and Conditions, and any
      dispute or claim arising therefrom or related thereto (in each case,
      including non-contractual disputes or claims), shall be governed by and
      construed in accordance with the internal laws of the State of Florida
      without giving effect to any choice or conflict of law provision or rule
      (whether of the State of Florida or any other jurisdiction).
    </p>
    <p class="p1 mb-4">
      Any legal suit, action, or proceeding arising out of, or related to, these
      Terms and Conditions or the Site shall be instituted exclusively in the
      federal courts of the United States or the courts of the State of Florida,
      in each case located in the County of Broward, although we retain the
      right to bring any suit, action, or proceeding against you for breach of
      these Terms and Conditions in your county, state, or country of residence
      or any other relevant county, state, or country. You waive any and all
      objections to the exercise of jurisdiction over you by such courts and to
      venue in such courts.
    </p>
    <p class="p1 mb-4"><strong>Arbitration </strong></p>
    <p class="p1 mb-4">
      At Company's sole discretion, it may require You to submit any disputes
      arising from these Terms and Conditions or use of the Site, including
      disputes arising from or concerning their interpretation, violation,
      invalidity, non-performance, or termination, to final and binding
      arbitration under the Rules of Arbitration of the American Arbitration
      Association applying Florida law.
    </p>
    <p class="p1 mb-4"><strong>Limitation on Time to File Claims </strong></p>
    <p class="p1 mb-4">
      ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
      THESE TERMS AND CONDITIONS OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
      YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR
      CLAIM IS PERMANENTLY BARRED.
    </p>
    <p class="p1 mb-4"><strong>Waiver and Severability </strong></p>
    <p class="p1 mb-4">
      No waiver by the Company of any term or condition set out in these Terms
      and Conditions shall be deemed a further or continuing waiver of such term
      or condition or a waiver of any other term or condition, and any failure
      of the Company to assert a right or provision under these Terms and
      Conditions shall not constitute a waiver of such right or provision.
    </p>
    <p class="p1 mb-4">
      If any provision of these Terms and Conditions is held by a court or other
      tribunal of competent jurisdiction to be invalid, illegal, or
      unenforceable for any reason, such provision shall be eliminated or
      limited to the minimum extent such that the remaining provisions of the
      Terms and Conditions will continue in full force and effect.
    </p>
    <p class="p5">
      <span class="s3"><strong>Entire Agreement&nbsp;</strong></span>
    </p>
    <p class="p1 mb-4">
      The Terms and Conditions, our Privacy Policy, estimate, and invoice, or
      order confirmation, constitute the sole and entire agreement between you
      and the Company regarding the Site and supersede all prior and
      contemporaneous understandings, agreements, representations, and
      warranties, both written and oral, regarding the Site.
    </p>
    <p class="p1 mb-4"><strong>Your Comments and Concerns </strong></p>
    <p class="p1 mb-4">
      This website is operated by <strong>Artistic Visions LLC </strong>located
      at 900 SE Ocean Boulevard, Building E Suite 244, Stuart, Florida 34994.
    </p>
    <p class="p1 mb-4">
      If you believe someone is infringing your copyright, you can report it to
      us by contact our designated agent identified below.
    </p>
    <p class="p1 mb-4">Aquileo Abello</p>
    <p class="p1 mb-4">Business Development Director | ARTISTIC VISIONS, LLC</p>
    <p class="p1 mb-4">
      900 SE Ocean Blvd | Bldg. E Suite 244 | Stuart, Fl 34994
    </p>
    <p class="p1 mb-4">(t) 772.419.7107</p>
    <p class="p1 mb-4">
      If you contact our designated agent, please be sure to include a complete
      copyright claim in your report with the following information:
    </p>
    <ul class="ul1">
      <li class="li9">
        Your complete contact information (full name, mailing address and phone
        number).*
      </li>
      <li class="li9">
        A description of the copyrighted work that you claim has been infringed.
      </li>
      <li class="li9">
        A description of the content on our site that you claim infringes your
        copyright.
      </li>
      <li class="li9">
        Information reasonably sufficient to permit us to locate the material
        alleged to be infringing.
      </li>
      <li class="li10">
        A declaration that:
        <ul class="ul1">
          <li class="li10">
            You have a good faith belief that use of the copyrighted content
            described above, in the manner you have complained of, is not
            authorized by the copyright owner, its agent, or the law.
          </li>
          <li class="li10">The information in your notice is accurate.</li>
          <li class="li1">
            Under penalty of perjury, you are the owner or authorized to act on
            behalf of the owner of an exclusive copyright that is allegedly
            infringed.
          </li>
        </ul>
      </li>
      <li class="li1">Your electronic signature or physical signature.</li>
    </ul>
    <p class="p2 mb-4">&nbsp;</p>
    <p class="p1 mb-4">
      All other feedback, comments, requests for technical support, and other
      communications relating to the Site should be directed to:
      support@avwaveinteractive.com.
    </p>
    <p>&nbsp;</p>
  </nb-card-body>
  <nb-card-footer>
    <section class="another-action" aria-label="Register">
      Return page <a class="text-link" routerLink="/auth/register">Register</a>
    </section>
  </nb-card-footer>
</nb-card>
